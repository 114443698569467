




import Vue from "vue";
import { Duration } from "luxon";
import * as _ from "lodash";

export default Vue.extend({
    name  : "LinePart",
    props : {
        chartData: {
            required : true,
            type     : Array,
            default  : ():any => [],
        },
        isTimeYAxis: {
            required : false,
            type     : Boolean
        },
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal    : true,
                        columnWidth   : "15%",
                        endingShape   : "rounded",
                        startingShape : "rounded",
                        distributed   : true,
                    },
                },
                colors     : ["#ff006c"],
                dataLabels : {
                    enabled: false,
                },
                grid: {
                    borderColor: "rgba(227,227,227)",
                },
                xaxis: {
                    axisTicks: {
                        show: false,
                    },
                    axisBorder: {
                        color: "#F3F6F8",
                    },
                    labels: {
                        style: {
                            colors: "#999",
                        },
                        rotate                : 0,
                        rotateAlways          : false,
                        hideOverlappingLabels : true,
                    }
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: "#999",
                        },
                        offsetY   : -5,
                        formatter : (value: number): string | number => {
                            if (this.isTimeYAxis) {
                                return Duration.fromObject({ seconds: Math.round(value) }).toFormat("hh:mm:ss");
                            }

                            return Math.round(value);
                        },
                    },
                    axisTicks: {
                        show       : true,
                        borderType : "solid",
                        color      : "rgba(227,227,227)",
                        width      : 30,
                        offsetX    : -1,
                        offsetY    : 5.6,
                    },
                    axisBorder: {
                        show    : true,
                        color   : "transparent",
                        offsetX : 0,
                    },
                    tickAmount: undefined,
                },
                legend: {
                    show: false,
                },
                stroke: {
                    curve   : "smooth",
                    lineCap : "curve",
                    width   : 2.4,
                },
                tooltip: {
                    x: {
                        show: false
                    }
                },
            },
        };
    },
    computed: {
        getChartHeight(): string {
            return this.$store.state.graphs.defaultGraphHeight;
        },
        getChartOptions(): ChartOptions {
            const customOptions: object = this.getTickAmount();
            return _.merge({}, this.chartOptions, customOptions);
        },
    },
    methods: {
        getTickAmount(): object {
            const customOptions = {
                yaxis: {
                    tickAmount: 0,
                }
            };
            if (this.chartData[0].data.length) {
                // search the highest amount of messages and set the tick interval
                const max: GraphData = this.chartData[0].data.reduce((prev: GraphData,
                    current: GraphData) => (prev.y > current.y) ? prev : current);

                if (max.y < 6) {
                    customOptions.yaxis.tickAmount = max.y;
                }
            }

            return customOptions;
        },
    }
});
