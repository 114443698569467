
















































import Vue from "vue";
import LinePart from "@/components/graphs/graphParts/LinePart.vue";
import GraphsMixin from "@/mixins/graphs-mixin";
import GraphHelp from "@/components/graphs/graphParts/GraphHelp.vue";
import GraphModal from "@/components/graphs/graphParts/GraphModal.vue";

export default Vue.extend({
    name       : "LineGraph",
    components : { LinePart, GraphHelp, GraphModal },
    mixins     : [ GraphsMixin ]
});
