import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import * as SaySimpleAuthenticator from "@saysimple/auth-module";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path      : "/insights",
        name      : "Home",
        component : Home
    }
];

const router = new VueRouter({
    mode : "history",
    base : process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if (to.name !== "Login" && ! SaySimpleAuthenticator.isLoggedIn()) {
        next({ path: "/" });
    } else {
        next();
    }
});

export default router;
