
















































import  BarPart from "@/components/graphs/graphParts/BarPart.vue";
import GraphsMixin from "@/mixins/graphs-mixin";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import GraphHelp from "@/components/graphs/graphParts/GraphHelp.vue";
import GraphModal from "@/components/graphs/graphParts/GraphModal.vue";

export default GraphsMixin.extend({
    name       : "FlagGraph",
    components : {
        BarPart, GraphHelp, GraphModal
    },
    mixins: [ GraphsMixin ],
    data() {
        return {
            activeSubmenu : false,
            dataName      : "Messages"
        };
    },
    computed: {
        getCustomOptions(): object {
            const customOptions = {
                plotOptions: {
                    bar: {
                        columnWidth: "12%",
                    }
                },
                xaxis: {
                    labels: {
                        style: {
                            fontSize: "26px"
                        }
                    },
                    offsetY: 0,
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: "#999",
                        },
                    },
                    tickAmount: 1
                },
                noData: {
                    text: "No Whatsapp data available"
                }
            };

            if (this.chartData.data[0]) {
                const dataEntry: any = this.chartData.data[0];
                //Set the interval equal to the highest amount of tags used until 6
                if (dataEntry.y < 6) {
                    customOptions.yaxis.tickAmount = dataEntry.y;
                }
            }

            switch (this.chartData.data.length != 0) {
                case (this.chartData.data.length < 2) :
                    customOptions.plotOptions.bar.columnWidth = "2%";
                    customOptions.xaxis.labels.style.fontSize = "26px";
                    break;
                case (this.chartData.data.length < 3) :
                    customOptions.plotOptions.bar.columnWidth = "5%";
                    customOptions.xaxis.labels.style.fontSize = "26px";
                    break;
                case (this.chartData.data.length < 5) :
                    customOptions.plotOptions.bar.columnWidth = "8%";
                    customOptions.xaxis.labels.style.fontSize = "26px";
                    break;
                case (this.chartData.data.length < 11) :
                    customOptions.plotOptions.bar.columnWidth = "10%";
                    customOptions.xaxis.labels.style.fontSize = "26px";
                    break;
            }

            return customOptions;
        },

        getChartData(): [{ data: object, name: string | undefined }] {
            this.chartData.data.forEach(function(entry: any) {
                if (entry.x !== null || entry.x !== undefined) {
                    entry.x = getUnicodeFlagIcon(entry.x);
                }
            });

            return [this.chartData];
        },
    },
});
