// @ts-nocheck

import _Vue from "vue";
import * as _ from "lodash";

const OutsideClickPlugin = {
    install(Vue: typeof _Vue): void {
        let handleOutsideClick;

        Vue.directive("click-outside", {
            bind(el, binding, vnode) {
                handleOutsideClick = (e): { target: Node | null; } => {
                    const { exclude, handler } = binding.value;
                    const matchingClassName = _.intersection(exclude, e.target.classList);

                    if (!el.contains(e.target) && matchingClassName.length === 0) {
                        vnode.context[handler]();
                    }
                };
                document.addEventListener("click", handleOutsideClick);
                document.addEventListener("touchstart", handleOutsideClick);
            },

            unbind() {
                document.removeEventListener("click", handleOutsideClick);
                document.removeEventListener("touchstart", handleOutsideClick);
            },
        });
    } 
};

export default OutsideClickPlugin;
