










import Vue from "vue";

export default Vue.extend({
    name  : "GraphModal",
    props : {
        graphId: {
            required : true,
            type     : Number,
        },
        title: {
            required : true,
            type     : String,
        },
        description: {
            required : true,
            type     : String
        }
    }
});
