

































































import Vue from "vue";
import CheckboxDropdown from "@/components/CheckboxDropdown.vue";
import PeriodDropdown from "@/components/PeriodDropdown.vue";
import { DateFilterValue } from "@/enums/date-filter-value";
import { DropdownFilters } from "@/enums/dropdown-filters";
import { AxiosResponse } from "axios";
import ApiConfig from "@/config/api-endpoint-config";
import * as SaySimpleAuthenticator from "@saysimple/auth-module";

export default Vue.extend({
    name       : "TopBar",
    components : {
        CheckboxDropdown,
        PeriodDropdown,
    },
    data () {
        return {
            agentsTitle        : DropdownFilters.AGENTS,
            channelsTitle      : DropdownFilters.CHANNELS,
            tagsTitle          : DropdownFilters.TAGS,
            teamsTitle         : DropdownFilters.TEAMS,
            selectedLabelIds   : [] as number[],
            selectedAgentIds   : [] as number[],
            selectedChannelIds : [] as number[],
            selectedTagsIds    : [] as number[],
            selectedTeamsIds   : [] as number[],
            disableButton      : false,
            labelsData         : [
                {
                    "id"     : 1,
                    "name"   : "Marketing",
                    "active" : true,
                },
                {
                    "id"     : 2,
                    "name"   : "VIP",
                    "active" : false,
                }
            ],
            agentsData   : [] as DropdownFilterValue[],
            channelsData : [] as DropdownFilterValue[],
            tagsData     : [] as DropdownFilterValue[],
            teamsData    : [] as DropdownFilterValue[],
            datePeriods  : {
                [DateFilterValue.TODAY]                : "Today",
                [DateFilterValue.YESTERDAY]            : "Yesterday",
                [DateFilterValue.DAY_BEFORE_YESTERDAY] : "Day before yesterday",
                [DateFilterValue.CURRENT_WEEK]         : "This week",
                [DateFilterValue.LAST_WEEK]            : "Last week",
                [DateFilterValue.CURRENT_MONTH]        : "This month",
                [DateFilterValue.LAST_28_DAYS]         : "Last 28 days",
                [DateFilterValue.LAST_MONTH]           : "Last month",
                [DateFilterValue.CURRENT_YEAR]         : "This year",
                [DateFilterValue.LAST_YEAR]            : "Last year",
                [DateFilterValue.CUSTOM]               : "Custom period",
            }
        };
    },
    methods: {
        async logout(): Promise<void> {
            // eslint-disable-next-line
            // @ts-ignore
            if (typeof this.$refs.logoutButton["disabled"] !== "undefined") {
                // eslint-disable-next-line
                // @ts-ignore
                this.$refs.logoutButton["disabled"] = true;
            }
            // eslint-disable-next-line
            await SaySimpleAuthenticator.logout();
            // eslint-disable-next-line
            window.location.pathname = "/";
        },
        showAddGraphMenu(): void {
            this.$addGraphMenu.show(-1);
        },
        hideAddGraphMenu(): void {
            this.$addGraphMenu.hide(true);
        },
        selectedLabels(data: number[]): void {
            this.selectedLabelIds = data;
        },
        selectedAgents(data: number[]): void {
            this.selectedAgentIds = data;
        },
        selectedChannels(data: number[]): void {
            this.selectedChannelIds = data;
        },
        selectedTags(data: number[]): void {
            this.selectedTagsIds = data;
        },
        selectedTeams(data: number[]): void {
            this.selectedTeamsIds = data;
        },
        getData(endpoint: string, isResponseObject = false): DropdownFilterValue[] {
            const dropdownValues: DropdownFilterValue[] = [];

            this.$getData(`${ApiConfig.getUrl()}/${endpoint}`)
                .then((result: AxiosResponse) => {
                    for (const i in result.data) {
                        dropdownValues.push({
                            id: isResponseObject
                                ? { value: result.data[i].channel, identifier: result.data[i].identifier }
                                : { value: result.data[i] },
                            name: isResponseObject
                                ? `${result.data[i].channel} ${result.data[i].identifier}`
                                : result.data[i],
                            active: false,
                        });
                    }
                });

            return dropdownValues;
        },
        getAgents(): void {
            const dropdownValues = this.getData("agents");

            dropdownValues.unshift({
                id           : { value: "agents-select-all" },
                name         : "Select all",
                active       : false,
                defaultLabel : true,
                toggleAll    : true
            }, {
                id           : { value: "" },
                name         : "No agent",
                active       : false,
                defaultLabel : true
            });

            this.agentsData = dropdownValues;
        },
        getChannels(): void {
            const dropdownValues = this.getData("channels", true);

            dropdownValues.unshift({
                id           : { value: "channels-select-all" },
                name         : "Select all",
                active       : false,
                defaultLabel : true,
                toggleAll    : true
            });

            this.channelsData = dropdownValues;
        },

        getTags(): void {
            const dropdownValues = this.getData("tags");

            dropdownValues.unshift({
                id           : { value: "tags-select-all" },
                name         : "Select all",
                active       : false,
                defaultLabel : true,
                toggleAll    : true
            }, {
                id           : { value: "" },
                name         : "No tags",
                active       : false,
                defaultLabel : true
            });

            this.tagsData = dropdownValues;
        },

        getTeams(): void {
            const dropdownValues = this.getData("teams");
          
            dropdownValues.unshift({
                id           : { value: "teams-select-all" },
                name         : "Select all",
                active       : false,
                defaultLabel : true,
                toggleAll    : true
            }, {
                id           : { value: "" },
                name         : "No teams",
                active       : false,
                defaultLabel : true
            });

            this.teamsData = dropdownValues;
        },

        applyFilters(): void {
            this.disableButton = true;
            this.$store.commit("graphs/applyFilters");
            setTimeout(() => this.disableButton = false, 5000);


        }
    },
    beforeMount() {
        // Getting data before mount to fill the filter dropdowns
        this.getAgents();
        this.getChannels();
        this.getTags();
        this.getTeams();
    },
});
