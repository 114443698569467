














import Vue from "vue";

export default Vue.extend({
    name  : "GraphSwitch",
    props : {
        text: {
            required : true,
            type     : String
        }
    },
    methods: {
        switchGraph(event: Event): void {
            event.preventDefault();
            this.$emit("switchGraph", true);
        }
    }
});
