


















import Vue from "vue";

export default Vue.extend({
    name  : "GraphHelp",
    props : {
        graphId: {
            required : true,
            type     : Number,
        }
    }
});
