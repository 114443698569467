































































import Vue from "vue";
import ConfirmBox from "@/components/ConfirmBox.vue";

export default Vue.extend({
    name  : "GraphSubmenu",
    props : {
        position: {
            type     : Number,
            required : true
        }
    },
    components: {
        ConfirmBox
    },
    data() {
        return {
            activeSubmenu  : false,
            confirmMessage : "Are you sure you want to delete this graph?",
            showConfirm    : false
        };
    },
    methods: {
        toggle(property: string): void {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            this[property] = ! this[property];
        },
        toggleConfirm(event: Event): void {
            event.preventDefault();
            this.showConfirm = !this.showConfirm;
        },
        remove(confirmed: boolean): void {
            if (confirmed) {
                this.$store.dispatch("graphs/removeGraph", this.position);
            }
            this.showConfirm = false;
            this.activeSubmenu = false;
        }
    }
});
