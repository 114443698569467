import _Vue from "vue";
import axios, { AxiosPromise } from "axios";
import * as SaySimpleAuthenticator from "@saysimple/auth-module";

const DataExtractorPlugin = {
    install(Vue: typeof _Vue): void {
        Vue.prototype.$getData = (url: string): AxiosPromise => {
            const bearerToken = SaySimpleAuthenticator.getAccessToken();
            return axios({
                method  : "GET",
                url,
                headers : {
                    Authorization: `Bearer ${bearerToken}`
                }
            }).then(response => response);
        };
    }
};

export default DataExtractorPlugin;

