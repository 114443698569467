

















































import BarPart from "@/components/graphs/graphParts/BarPart.vue";
import GraphsMixin from "@/mixins/graphs-mixin";
import GraphHelp from "@/components/graphs/graphParts/GraphHelp.vue";
import GraphModal from "@/components/graphs/graphParts/GraphModal.vue";

export default GraphsMixin.extend({
    name       : "BarGraph",
    components : {
        BarPart, GraphHelp, GraphModal
    },
    mixins: [ GraphsMixin ],
    data() {
        return {
            activeSubmenu : false,
            dataName      : "Messages"
        };
    },
    computed: {
        getChartData(): [{ data: object[], name: string | undefined }] {

            return [this.chartData];
        },
        getCustomOptions(): object {
            const customOptions = {
                yaxis: {
                    tickAmount: undefined,
                }
            };

            if (this.chartData.data[0]) {
                //search the highest amount of messages and set the tick interval
                const max: any = this.chartData.data.reduce((prev: any, current: any) => (prev.y > current.y) ? prev : current);

                if (max.y < 6) {
                    customOptions.yaxis.tickAmount = max.y;
                }
            }

            return customOptions;
        },
    },
});
