












































































import GraphButton from "@/components/graphs/graphParts/GraphButton.vue";
import LinePart from "@/components/graphs/graphParts/LinePart.vue";
import GraphsMixin from "@/mixins/graphs-mixin";
import GraphHelp from "@/components/graphs/graphParts/GraphHelp.vue";
import GraphModal from "@/components/graphs/graphParts/GraphModal.vue";

export default GraphsMixin.extend({
    name       : "NumberGraph",
    components : { GraphButton, LinePart, GraphHelp, GraphModal },
    mixins     : [ GraphsMixin ],
    data() {
        return {
            activeSubmenu : false,
            isNumber      : true
        };
    }
});
