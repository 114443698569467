





































import Vue from "vue";
import GraphHelp from "@/components/graphs/graphParts/GraphHelp.vue";
import GraphModal from "@/components/graphs/graphParts/GraphModal.vue";

export default Vue.extend({
    name       : "CountryGraph",
    components : {
        GraphHelp, GraphModal
    },
    props: {
        chartData: {
            type     : Object,
            required : true
        },
        dataPath: {
            type     : String,
            required : false,
        },
        graphPosition: {
            type     : Number,
            required : true
        }
    },
    data() {
        return {
            activeSubmenu : false,
            countries     : [
                "NL",
                "GR",
                "DE",
                "UK",
                "US"
            ],
            chartOptions: { 
                chart: {
                    toolbar: {
                        show: false
                    },
                    sparkline: {
                        enabled: true
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal    : false,
                        endingShape   : "rounded",
                        startingShape : "rounded",
                        distributed   : true,
                        barHeight     : "20%",
                        columnWidth   : "10%",
                        colors        : {
                            backgroundBarColors : ["#FF006C1A"],
                            backgroundBarRadius : "5"
                        }
                    },
                },
                colors     : ["#ff006c"],
                dataLabels : {
                    enabled : false,
                    color   : "#B0B0B0",
                },
                grid: {
                    borderColor: "#F3F6F8"
                },
                xaxis: {
                    axisTicks: {
                        color: "#F3F6F8"
                    },
                    axisBorder: {
                        color: "#F3F6F8"
                    },
                    categories: ["M"]
                },
                yaxis: {
                    max: 1000
                },
                legend: {
                    show: false
                }
            },
        };
    },
    computed: {
        getChartWidth(): string {
            return this.$store.state.graphs.defaultCountryGraphWidth;
        },
        getChartHeight(): string {
            return this.$store.state.graphs.defaultCountryGraphHeight;
        },
    }
});
