(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("uikit"), require("vue-router"), require("vue"), require("@saysimple/auth-module"));
	else if(typeof define === 'function' && define.amd)
		define(["uikit", "vue-router", "vue", "@saysimple/auth-module"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("uikit"), require("vue-router"), require("vue"), require("@saysimple/auth-module")) : factory(root["uikit"], root["vue-router"], root["vue"], root["@saysimple/auth-module"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__3210__, __WEBPACK_EXTERNAL_MODULE__6389__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_ab54__) {
return 