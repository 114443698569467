import _Vue from "vue";
import { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { DateFilterValue } from "@/enums/date-filter-value";
import { DateFormattingOptions } from "@/enums/date-formatting-options";

const DataFormatterPlugin = {
    install(Vue: typeof _Vue): void {
        Vue.prototype.$formatData = (result: AxiosResponse, timePeriod: DateFilterValue): GraphData[] => {
            const format = this.formats[timePeriod];
            const aggregatedBy = result.data.aggregatedBy as keyof typeof DateFormattingOptions;
            const data = result.data.data;

            if (DateFormattingOptions[aggregatedBy] === DateFormattingOptions.WEEK) {
                return this.formatWeekData(data);
            } else if (timePeriod === DateFilterValue.LAST_MONTH && DateTime.fromISO(Object.keys(data)[0]).isValid) {
                return this.formatDataByLastMonth(data);
            }

            if (DateTime.fromISO(Object.keys(data)[0]).isValid) {
                return this.formatDataDefault(data, format);
            }
            return this.formatDataDefault(data);
        };
    },
    formats: {
        [DateFilterValue.TODAY]                : DateFormattingOptions.HOUR,
        [DateFilterValue.YESTERDAY]            : DateFormattingOptions.HOUR,
        [DateFilterValue.DAY_BEFORE_YESTERDAY] : DateFormattingOptions.HOUR,
        [DateFilterValue.CURRENT_WEEK]         : DateFormattingOptions.WEEKDAY,
        [DateFilterValue.LAST_WEEK]            : DateFormattingOptions.WEEKDAY,
        [DateFilterValue.CURRENT_MONTH]        : DateFormattingOptions.DAY,
        [DateFilterValue.LAST_28_DAYS]         : DateFormattingOptions.DAY,
        [DateFilterValue.LAST_MONTH]           : DateFormattingOptions.WEEK,
        [DateFilterValue.CURRENT_YEAR]         : DateFormattingOptions.MONTH,
        [DateFilterValue.LAST_YEAR]            : DateFormattingOptions.MONTH,
        [DateFilterValue.CUSTOM]               : DateFormattingOptions.DAY,
    },
    formatDate(date?: string, format?: string): string {
        return DateTime.fromISO(date ?? DateTime.local().toString())
            .setLocale("en-US")
            .toFormat(format ?? "d");
    },
    formatDataByLastMonth(data: { [index: string]: number }): GraphData[] {
        const graphData: GraphData[] = [];

        for (const key in data){
            const isMonday = (DateTime.fromISO(key).weekday === 1);
            graphData.push({
                x : isMonday ? "Week " + DataFormatterPlugin.formatDate(key, DataFormatterPlugin.formats.lastMonth) : "",
                y : data[key]
            });
        }

        return graphData;
    },
    formatDataDefault(data: { [index: string]: number }, format?: string): GraphData[] {
        const graphData: GraphData[] = [];
        
        for (const key in data){
            graphData.push({
                x : format ? this.formatDate(key, format) : key,
                y : data[key]
            });
        }
        return graphData;
    },
    formatWeekData(data: { [index: string]: number }): GraphData[] {
        const graphData: GraphData[] = [];
        for (const key in data){
            const date = key.split("-");

            // Its possible that week 53/52 continues in the next year
            // When that happens that data will be set to week 0
            // If we have a week 0 in our data we need to set it to week 53 or 52
            // If the previous year was a leap year set week 0 to week 53
            if (date[1] == "0") {
                const isLeap = new Date(parseInt(date[0]) - 1, 1, 29).getDate() === 29;
                date[1] = isLeap ? "53" : "52";
            }
            graphData.push({
                x : "Week " + date[1],
                y : data[key]
            });
        }

        return graphData;
    }
};

export default DataFormatterPlugin;
