import { DateTime } from "luxon";
import { DateFilterValue } from "@/enums/date-filter-value";
import Vue from "vue";
import { DropdownFilters } from "@/enums/dropdown-filters";

export const GraphStoreBus = new Vue();

export const graphs = {
    namespaced : true,
    state      : (): object => ({
        layout: [
            null,
            null,
            null,
            null,
            null,
            null
        ],
        //Below width and height values from graphs
        defaultGraphHeight         : "170px", //Used in the Line and Bar Graph
        defaultCountryGraphHeight  : "125px",
        defaultCountryGraphWidth   : "15px",
        defaultAppsConvGraphWidth  : "125px",
        defaultAppsConvGraphHeight : "40px",

        // Filter defaults
        filters: {
            startDate  : DateTime.utc().minus({ days: 27 }).toString(),
            endDate    : DateTime.utc(),
            timePeriod : DateFilterValue.LAST_28_DAYS,
            agents     : [],
            channels   : [],
            tags       : [],
            teams      : [],
        }
    }),
    mutations: {
        addToLayout(state: any, { graph, position }: { graph: object, position: number }): void {
            const emptyIndex = state.layout.findIndex((graph: object) => graph == null);            
            
            if (emptyIndex == -1 && position < 0) {
                state.layout.push(graph);
            } else if (position < 0) {
                state.layout.splice(emptyIndex, 1, graph);
            } else {
                state.layout.splice(position, 1, graph);
            } 
        },
        removeFromLayout(state: any, position: number): void {
            state.layout.splice(position, 1, null);
        },
        mutateDates(state: any,  { startDate, endDate, timePeriod }: { startDate: string, endDate: string, timePeriod: DateFilterValue }): void {
            state.filters.startDate = startDate;
            state.filters.endDate = endDate;
            state.filters.timePeriod = timePeriod;
        },
        mutateFilterDropdowns(state: any, { filterType, data }: { filterType: string, data: {}}): void {
            const filters: { [index: string] : string } = {
                [DropdownFilters.AGENTS]   : "agents",
                [DropdownFilters.CHANNELS] : "channels",
                [DropdownFilters.TAGS]     : "tags",
                [DropdownFilters.TEAMS]    : "teams",
            };

            state.filters[filters[filterType]] = data;
        },
        applyFilters(state: any): void {
            GraphStoreBus.$emit("filterUpdated", state.filters);
        },
    },
    actions: {
        addGraph({ commit }: any, { graph, position = -1 }: { graph: object, position?: number }): void {
            commit("addToLayout", { graph, position });
        },
        removeGraph({ commit }: any, position: number): void {
            commit("removeFromLayout", position);
        }
    }
};
