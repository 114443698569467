import _Vue from "vue";
import { Filters } from "@/types/filters";
import * as _ from "lodash";
import ApiConfig from "@/config/api-endpoint-config";

const URLBuilderPlugin = {
    install(Vue: typeof _Vue): void {
        Vue.prototype.$buildURL = (path: string, filters: Filters): string => {
            const baseUrl = _.trimEnd(ApiConfig.getUrl(), "/");
            path = "/" + _.trimStart(path, "/");

            const urlBuilder = new URL(`${baseUrl}${path}`);

            if (filters.startDate) {
                urlBuilder.searchParams.append("between", filters.startDate);
            }

            if (filters.endDate) {
                urlBuilder.searchParams.append("and", filters.endDate);
            }

            if (filters.agents) {
                filters.agents.forEach(agent => {
                    urlBuilder.searchParams.append("agent", agent.id.value);
                });
            }

            if (filters.channels) {
                filters.channels.forEach(channel => {
                    urlBuilder.searchParams.append("channel", channel.id.value);
                    if (channel.id.identifier) {
                        urlBuilder.searchParams.append("identifier", channel.id.identifier);
                    }
                });
            }

            if (filters.tags) {
                filters.tags.forEach(tag => {
                    urlBuilder.searchParams.append("tags", tag.id.value);
                });
            }

            if (filters.teams) {
                filters.teams.forEach(team => {
                    urlBuilder.searchParams.append("team", team.id.value);
                });
            }

            return urlBuilder.href;
        };
    }
};

export default URLBuilderPlugin;
