




























import Vue from "vue";
import { DateTime } from "luxon";
import { DateFilterValue } from "@/enums/date-filter-value";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default Vue.extend({
    name  : "CheckboxDropdown",
    props : {
        title: {
            required : true,
            type     : String,
        },
        selected: {
            required : false,
            type     : String,
        },
        data: {
            required : true,
            type     : Object,
        },
    },
    components: {
        DatePicker
    },
    data() {
        return {
            isVisible : false,
            period    : "",
            date      : {
                start : DateTime.utc(),
                end   : DateTime.utc(),
            },
            from        : "",
            to          : "",
            modelConfig : {
                type: "string",
            },
            aggregationTypes: [
                {
                    minutes : 1220,
                    value   : DateFilterValue.TODAY,
                },
                {
                    minutes : 2880,
                    value   : DateFilterValue.CURRENT_WEEK,
                },
                {
                    minutes : 9970,
                    value   : DateFilterValue.CURRENT_MONTH,
                },
                {
                    minutes : 44640,
                    value   : DateFilterValue.CURRENT_YEAR,
                },
                {
                    minutes : 1051200,
                    value   : DateFilterValue.CURRENT_YEAR,
                },
                {
                    minutes : 2628000,
                    value   : DateFilterValue.LAST_YEAR,
                },
            ],
        };
    },
    mounted() {
        this.period = this.data.last28Days;
    },
    watch: {
        date: function(): void {
            this.emitData("Custom period", "Custom");

            this.from = DateTime.fromISO(this.date.start.toString()).toLocaleString(DateTime.DATE_FULL);
            this.to   = DateTime.fromISO(this.date.end.toString()).toLocaleString(DateTime.DATE_FULL);
        },
    },
    methods: {
        emitData(selectedValue: string, selectedDate: string): void {
            this.isVisible = selectedValue === "Custom period";

            const date = DateTime.utc();
            const today = DateTime.utc(date.year, date.month, date.day, 0, 0, 0, 0);
            const selectableDates: { [index: string] : DateTime[] } = {
                [DateFilterValue.TODAY]: [
                    today,
                    today.plus({ days: 1 })
                ],
                [DateFilterValue.YESTERDAY]: [
                    today.minus({ days: 1 }),
                    today
                ],
                [DateFilterValue.DAY_BEFORE_YESTERDAY]: [
                    today.minus({ days: 2 }),
                    today.minus({ days: 1 })
                ],
                [DateFilterValue.CURRENT_WEEK]: [
                    today.minus({ days: today.weekday - 1 }),
                    today.plus({ days: 8 - today.weekday })
                ],
                [DateFilterValue.LAST_WEEK]: [
                    today.minus({ days: today.weekday + 6 }),
                    today.minus({ days: today.weekday - 1 })
                ],
                [DateFilterValue.CURRENT_MONTH]: [
                    today.minus({ days: today.day - 1 }),
                    today.plus({ months: 1 }).minus({ days: today.day - 1 })
                ],
                [DateFilterValue.LAST_28_DAYS]: [
                    today.minus({ days: 27 }),
                    today.plus({ days: 1 })
                ],
                [DateFilterValue.LAST_MONTH]: [
                    today.minus({ days: today.day - 1 + today.minus({ days: today.day }).day }),
                    today.minus({ days: today.day - 1 })
                ],
                [DateFilterValue.CURRENT_YEAR]: [
                    DateTime.utc(today.year, 1, 1),
                    DateTime.utc(today.year + 1, 1, 1),
                ],
                [DateFilterValue.LAST_YEAR]: [
                    DateTime.utc(today.year - 1, 1, 1),
                    DateTime.utc(today.year, 1, 1)
                ],
                [DateFilterValue.CUSTOM]: [
                    DateTime.fromISO(this.date.start.toString()).plus({ hour: 2 }),
                    DateTime.fromISO(this.date.end.toString()).plus({ day: 1 }),
                ],
            };

            if (this.period !== selectedValue || this.period === "Custom period") {
                this.period = selectedValue;
                this.storeDates(selectedDate as DateFilterValue, ...selectableDates[selectedDate] as [DateTime, DateTime]);
            }
        },
        storeDates(timePeriod: DateFilterValue, startDate: DateTime, endDate: DateTime): void {
            if (timePeriod === "Custom") {
                const difference  = endDate.diff(startDate, "minutes").toObject();
                const minutes     = difference.minutes || 0;
                timePeriod = DateFilterValue.LAST_28_DAYS;

                this.aggregationTypes.forEach(aggregation => {
                    if (minutes >= aggregation.minutes) {
                        timePeriod = aggregation.value;
                    }
                });
            }

            this.$store.commit("graphs/mutateDates", {
                startDate : startDate.toString(),
                endDate   : endDate.toString(),
                timePeriod
            });
        },
    }
});
