import Vue from "vue";
import { AxiosResponse } from "axios";
import { GraphStoreBus } from "@/store/graphs";
import { Filters } from "@/types/filters";

export default Vue.extend({
    props: {
        id: {
            type     : Number,
            required : true,
        },
        title: {
            type     : String,
            required : true,
        },
        graphPosition: {
            type     : Number,
            required : true,
        },
        dataPath: {
            type     : String,
            required : false,
        },
        aggregatorPath: {
            type     : String,
            required : false,
        },
        dataLabel: {
            type     : String,
            required : false,
        },
        filters: {
            type     : Object,
            required : true
        },
        infoText: {
            type     : String,
            required : false
        },
        description: {
            type     : String,
            required : false,
        }
    },
    data() {
        return {
            chartData         : { data: [] as object[], name: undefined as string | undefined },
            aggregatedValue   : 0,
            numberDisplay     : 0,
            animatingInterval : 0,
            dataName          : this.dataLabel ?? "Amount",
            aggregator        : this.aggregatorPath ?? "",
            difference        : 0,
        };
    },
    beforeMount() {
        GraphStoreBus.$on("filterUpdated", (filters: Filters) => {
            this.aggregatedValue = 0;
            this.numberDisplay = 0;
            this.getData(this.$buildURL(this.dataPath + this.aggregator, filters));
        });
    },
    mounted() {
        this.getData(this.$buildURL(this.dataPath + this.aggregator, this.filters));
    },
    methods: {
        getData(value: string): void {
            this.$getData(value)
                .then((result: AxiosResponse) => {
                    const data = this.$formatData(result, this.filters.timePeriod);
                    this.aggregatedValue = Math.round(result.data.aggregatedValue);
                    const previousAggregatedValue = Math.round(result.data.previousAggregatedValue);

                    if (this.aggregatedValue === previousAggregatedValue) {
                        this.difference = 0;
                    } else if (this.aggregatedValue === 0) {
                        this.difference = -100;
                    } else if (previousAggregatedValue === 0) {
                        this.difference = 100;
                    } else {
                        const difference = this.aggregatedValue - previousAggregatedValue;
                        this.difference = (difference / this.aggregatedValue) * 100;
                    }

                    this.aggregatedValue = result.data.aggregatedValue;

                    this.chartData = {
                        name : this.dataName,
                        data : data
                    };
                });
        },
    },
    computed: {
        getChartData(): [{ data: object[], name: string | undefined }] {
            return [this.chartData];
        },
        getAggregatedValue(): string {
            return Math.abs(this.numberDisplay).toLocaleString();
        },
        differenceLocal(): string {
            return this.$formatNumber(Math.abs(this.difference));
        }
    },
    watch: {
        aggregatedValue: function(): void {
            clearInterval(this.animatingInterval);
            if (this.numberDisplay === this.aggregatedValue) {
                return;
            }
            this.animatingInterval = setInterval(() => {
                let change = (this.aggregatedValue - this.numberDisplay) / 10;
                change = change >= 0 ? Math.ceil(change) : Math.floor(change);
                this.numberDisplay += change;
            }, 20);
        }
    },
});

