


































import Vue from "vue";

export default Vue.extend({
    name  : "AddGraphContainer",
    props : {
        graph: {
            required : true,
            type     : Object
        },
        graphPosition: {
            required : false,
            type     : Number
        }
    },
    data () {
        return {
            moreInfo: false
        };
    },
    methods: {
        toggleMoreInfo(): void {
            this.moreInfo = !this.moreInfo;
        },
        addGraph(): void {
            const graph = this.graph;
            const position = this.graphPosition;

            this.$store.dispatch("graphs/addGraph", { graph, position });

            this.$addGraphMenu.hide();
        }
    }
});
