import "./set-public-path";
import Vue from "vue";
import singleSpaVue from "single-spa-vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import UIkit from "uikit";
import VueApexCharts from "vue-apexcharts";
import VueLodash from "vue-lodash";
import lodash from "lodash";

// Ignore next line, since there are no types defined for this external library
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import * as Icons from "uikit/dist/js/uikit-icons.js";

// Import plugin
import AddGraphPlugin from "@/plugins/show-add-graph-menu.ts";
import NumberFormatPlugin from "@/plugins/number-formatter.ts";
import OutsideClickPlugin from "@/plugins/outside-click.ts";
import DataExtractorPlugin from "@/plugins/data-extractor.ts";
import URLBuilderPlugin from "@/plugins/url-builder.ts";
import DataFormatterPlugin from "@/plugins/data-formatter.ts";

Vue.use(AddGraphPlugin);
Vue.use(NumberFormatPlugin);
Vue.use(OutsideClickPlugin);
Vue.use(DataExtractorPlugin);
Vue.use(URLBuilderPlugin);
Vue.use(DataFormatterPlugin);

// Ignore next line, since types exposed for UIKit are out-dated ('use' was not there in v2)
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
UIkit.use(Icons);
Vue.config.productionTip = false;

Vue.use(VueApexCharts);
Vue.component("apexcharts", VueApexCharts);

Vue.use(VueLodash, { lodash: lodash });

const vueLifecycles = singleSpaVue({
    Vue,
    appOptions: {
        render(h: any): Promise<unknown> {
            return h(App, {
                props: {
                    // single-spa props are available on the "this" object. Forward them to your component as needed.
                    // https://single-spa.js.org/docs/building-applications#lifecyle-props
                    name        : this.name,
                    mountParcel : this.mountParcel,
                    singleSpa   : this.singleSpa,
                },
            });
        },
        router,
        store
    },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
