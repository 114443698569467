import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { graphs } from "./graphs";

export default new Vuex.Store({
    state     : {},
    mutations : {},
    actions   : {},
    modules   : {
        graphs
    }
});
