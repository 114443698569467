









import Vue from "vue";

export default Vue.extend({
    name  : "AddGraphBox", 
    props : {
        graphPosition: {
            required : true,
            type     : Number
        }
    },
    methods: {
        showAddGraphMenu(): void {
            this.$addGraphMenu.show(this.graphPosition);
        }
    }
});
