












import Vue from "vue";

export default Vue.extend({
    name  : "ConfirmBox",
    props : {
        text: {
            required : true,
            type     : String
        }
    },
    methods: {
        cancel(): void {
            this.$emit("confirmed", false);
        },
        confirm(): void {
            this.$emit("confirmed", true);
        },
    }
});
