









import Vue from "vue";
import { Duration } from "luxon";
import * as _ from "lodash";

export default Vue.extend({
    name  : "BarPart",
    props : {
        chartData: {
            required : true,
            type     : Array
        },
        isTimeYAxis: {
            required : false,
            type     : Boolean
        },
        isHorizontal: {
            required : false,
            type     : Boolean,
            default  : false,
        },
        customChartHeight: {
            required : false,
            type     : Number,
        },
        customOptions: {
            required : false,
            type     : Object
        },
    },
    data() {
        return {
            color               : "#FF006C",
            averageColor        : "#8720E0",
            defaultChartOptions : {
                chart: {
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal    : this.isHorizontal,
                        columnWidth   : "10%",
                        endingShape   : "rounded",
                        startingShape : "rounded",
                        distributed   : true,
                    },
                },
                colors     : ["#FF006C"],
                dataLabels : {
                    enabled : false,
                    color   : "#B0B0B0",
                },
                grid: {
                    borderColor: "rgba(227,227,227)",
                },
                xaxis: {
                    labels: {
                        style: {
                            colors: "#999",
                        },
                    },
                    tickAmount : 2,
                    axisTicks  : {
                        color: "rgba(227,227,227)",
                    },
                    axisBorder: {
                        show    : false,
                        offsetY : -0.6,
                        color   : "rgba(227,227,227)",
                    },
                    categories: new Array<string>() || new Array<number>(),
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: "#999",
                        },
                        offsetY   : -5,
                        formatter : (val: number): string | number => {
                            if (this.isTimeYAxis) {
                                return Duration.fromObject({ seconds: Math.round(val) }).toFormat("hh:mm:ss");
                            }

                            return val;
                        },
                    },
                    axisTicks: {
                        show       : true,
                        borderType : "solid",
                        color      : "rgba(227,227,227)",
                        width      : 30,
                        offsetX    : -1,
                        offsetY    : 5.5,
                    },
                    axisBorder: {
                        show    : true,
                        color   : "transparent",
                        offsetX : 0,
                    },
                    decimalsInFloat: 1,
                },
                legend: {
                    show: false,
                },
                noData: {
                    text          : "No data available",
                    align         : "center",
                    verticalAlign : "center",
                    style         : {
                        color    : "#faa05a",
                        fontSize : "0.875rem"
                    }
                }
            },
        };
    },
    computed: {
        getChartHeight(): number {
            if (this.customChartHeight) {
                return this.customChartHeight;
            }

            return this.$store.state.graphs.defaultGraphHeight;
        },
        getChartOptions(): ChartOptions {
            return _.merge({}, this.defaultChartOptions, this.customOptions);
        },
    },
});
