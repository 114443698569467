

























import Vue from "vue";
import * as _ from "lodash";

export default Vue.extend({
    name  : "CheckboxDropdown",
    props : {
        title: {
            type     : String,
            required : true,
        },
        data: {
            type     : Array,
            default  : ():any => [],
            required : true,
        }
    },
    data() {
        return {
            checkboxData    : [],
            selectAllActive : false,
        };
    },
    beforeMount() {
        if (this.data.length) {
            this.checkboxData = this.data;
        }
    },
    methods: {
        emitData(toggleAll: boolean): void {
            const ids: DropdownFilterValue[] = toggleAll ? [] : this.checkboxData.filter((i: { active: boolean, id: number }) => i.active);
            this.$store.commit("graphs/mutateFilterDropdowns", {
                filterType : this.title,
                data       : ids
            });
        },
        toggleAll(item: DropdownFilterValue): void {
            item.active = !item.active;

            // If select all is checked and an option is unchecked, uncheck toggle all
            this.uncheckSelectAll();

            // If checkbox is select all, toggle all dropdown options
            if (item.toggleAll) {
                for (const label in this.data) {
                    this.data[label].active = item.active;
                }

                this.selectAllActive = item.active;
            }
        },
        uncheckSelectAll(): void {
            if (this.selectAllActive) {
                const selectAll = _.find(this.data, "toggleAll");
                selectAll.active = false;
                this.selectAllActive = false;
            }
        }
    },
    computed: {
        selectedOptionsCount(): number {
            let selected = 0;

            this.checkboxData.forEach((item: DropdownFilterValue) => {
                if (item.active && !item.toggleAll) {
                    selected++;
                }
            });
            
            return selected;
        },
        optionsCount(): number {
            return _.find(this.data, "toggleAll") ? this.data.length - 1 : this.data.length;
        }
    }
});
