












































































import Vue from "vue";
import GraphSubmenu from "@/components/graphs/graphParts/GraphSubmenu.vue";
import GraphHelp from "@/components/graphs/graphParts/GraphHelp.vue";
import GraphModal from "@/components/graphs/graphParts/GraphModal.vue";

export default Vue.extend({
    name       : "AppsConversationGraph",
    components : { GraphSubmenu, GraphHelp, GraphModal },
    props      : {
        chartDataIn: {
            type     : Array,
            required : true,
        },
        chartDataOut: {
            type     : Array,
            required : true,
        },
        chartDataTotal: {
            type     : Array,
            required : true,
        },
        title: {
            type     : String,
            required : true,
        },
        dataPath: {
            type     : String,
            required : false,
        },
        graphPosition: {
            type     : Number,
            required : true,
        },
    },
    data() {
        return {
            activeSubmenu       : false,
            total               : 0,
            defaultChartOptions : { 
                chart: {
                    toolbar: {
                        show: false,
                    },
                    sparkline: {
                        enabled: true,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal    : true,
                        endingShape   : "rounded",
                        startingShape : "rounded",
                        distributed   : true,
                        barHeight     : "22%",
                        colors        : {
                            backgroundBarColors : ["#FF006C1A"],
                            backgroundBarRadius : "5",
                        }
                    },
                },
                colors     : ["#ff006c"],
                dataLabels : {
                    enabled : false,
                    color   : "#B0B0B0",
                },
                grid: {
                    borderColor: "#F3F6F8",
                },
                xaxis: {
                    axisTicks: {
                        color: "#F3F6F8",
                    },
                    axisBorder: {
                        color: "#F3F6F8",
                    },
                    categories: ["M"],
                },
                yaxis: {
                    max: 0,
                },
                legend: {
                    show: false,
                },
            },
        };
    },
    computed: {
        getChartOptions(): ChartOptions {
            const defaultChartOptions = { 
                ...this.defaultChartOptions, 
                ...{
                    yaxis: {
                        max: this.total,
                    },
                },
            };
            return defaultChartOptions;
        },
        getChartOptionsTotal(): ChartOptions {
            const defaultChartOptions = { 
                ...this.defaultChartOptions, 
                ...{
                    plotOptions: {
                        bar: {
                            horizontal    : true,
                            endingShape   : "rounded",
                            startingShape : "rounded",
                            distributed   : true,
                            barHeight     : "22%",
                            colors        : {
                                backgroundBarColors : ["#F1E5FF"],
                                backgroundBarRadius : "5",
                            },
                        },
                    },
                    colors : ["#8720E0"],
                    yaxis  : {
                        max: this.total,
                    },
                },
            };            

            return defaultChartOptions;
        },
        getChartWidth(): string {
            return this.$store.state.graphs.defaultAppsConvGraphWidth;
        },
        getChartHeight(): string {
            return this.$store.state.graphs.defaultAppsConvGraphHeight;
        },
    },
});
