














































































import GraphButton from "@/components/graphs/graphParts/GraphButton.vue";
import BarPart from "@/components/graphs/graphParts/BarPart.vue";
import GraphsMixin from "@/mixins/graphs-mixin";
import GraphSwitch from "@/components/graphs/graphParts/GraphSwitch.vue";
import GraphSubmenu from "@/components/graphs/graphParts/GraphSubmenu.vue";
import GraphHelp from "@/components/graphs/graphParts/GraphHelp.vue";
import GraphModal from "@/components/graphs/graphParts/GraphModal.vue";

export default GraphsMixin.extend({
    name       : "NumberGraph",
    components : { GraphButton, BarPart, GraphSwitch, GraphSubmenu, GraphHelp, GraphModal },
    mixins     : [ GraphsMixin ],
    data() {
        return {
            isNumber  : true,
            switchTo  : "Average",
            isAverage : true,
        };
    },
    methods: {
        switchMode(): void {
            this.isAverage = !this.isAverage;

            if (this.isAverage) {
                this.switchTo = "Average";
                this.aggregator = "/average";
            } else {
                this.switchTo = "Median";
                this.aggregator = "/median";
            }

            this.getData(this.$buildURL(this.dataPath + this.aggregator, this.filters));
        },
    },
    computed: {
        timeLocal(): string {
            const hours   = Math.floor(this.aggregatedValue / 3600).toString();
            const minutes = Math.floor((this.aggregatedValue % 3600) / 60).toString();
            const seconds = Math.floor(this.aggregatedValue % 60).toString();

            return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}:${seconds.padStart(2, "0")}`;
        },
        differenceLocal(): string {
            this.difference = this.difference * -1;
            return this.$formatNumber(Math.abs(this.difference));
        }
    },
});
