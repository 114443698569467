export enum DateFilterValue {
    TODAY                = "today",
    YESTERDAY            = "yesterday",
    DAY_BEFORE_YESTERDAY = "dayBeforeYesterday",
    CURRENT_WEEK         = "thisWeek",
    LAST_WEEK            = "lastWeek",
    CURRENT_MONTH        = "thisMonth",
    LAST_28_DAYS         = "last28Days",
    LAST_MONTH           = "lastMonth",
    CURRENT_YEAR         = "thisYear",
    LAST_YEAR            = "lastYear",
    CUSTOM               = "Custom",
}
