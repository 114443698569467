
















































import BarPart from "@/components/graphs/graphParts/BarPart.vue";
import GraphsMixin from "@/mixins/graphs-mixin";
import GraphHelp from "@/components/graphs/graphParts/GraphHelp.vue";
import GraphModal from "@/components/graphs/graphParts/GraphModal.vue";

export default GraphsMixin.extend({
    name       : "VerticalBarGraph",
    components : {
        BarPart, GraphHelp, GraphModal
    },
    mixins: [ GraphsMixin ],
    data() {
        return {
            activeSubmenu : false,
            dataName      : "Tags used"
        };
    },
    computed: {
        getChartData(): [{ data: object[], name: string | undefined }] {
            return [this.chartData];
        },
        getCustomOptions(): object {
            const customOptions = {
                plotOptions: {
                    bar: {
                        barHeight: "50%",
                    },
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: "#999",
                        },
                        offsetY: 3
                    },
                    axisTicks: {
                        offsetY: 0,
                    },
                    axisBorder: {
                        show    : true,
                        color   : "transparent",
                        offsetX : 1,
                    },
                },
                xaxis: {
                    labels: {
                        style: {
                            colors: "#999",
                        },
                        formatter: (val: number): string | number => {
                            return Math.round(val);
                        },
                    },
                    tickAmount: 0,
                },
            };

            if (this.chartData.data[0]) {
                const dataEntry: any = this.chartData.data[0];
                //Set the interval equal to the highest amount of tags used until 6
                if (dataEntry.y < 6) {
                    customOptions.xaxis.tickAmount = dataEntry.y;
                }
            }

            switch (this.chartData.data.length != 0) {
                case (this.chartData.data.length < 2) :
                    customOptions.plotOptions.bar.barHeight = "10%";
                    customOptions.yaxis.labels.offsetY = -1;
                    break;
                case (this.chartData.data.length < 3) :
                    customOptions.plotOptions.bar.barHeight = "20%";
                    customOptions.yaxis.labels.offsetY = 1;
                    break;
                case (this.chartData.data.length < 5) :
                    customOptions.plotOptions.bar.barHeight = "35%";
                    customOptions.yaxis.labels.offsetY = 2;
                    break;
                case (this.chartData.data.length < 6) :
                    customOptions.plotOptions.bar.barHeight = "45%";
                    break;
            }

            return customOptions;
        },
    },
});
