
















































import LinePart from "@/components/graphs/graphParts/LinePart.vue";
import GraphsMixin from "@/mixins/graphs-mixin";
import GraphSubmenu from "@/components/graphs/graphParts/GraphSubmenu.vue";
import { Duration } from "luxon";
import GraphHelp from "@/components/graphs/graphParts/GraphHelp.vue";
import GraphModal from "@/components/graphs/graphParts/GraphModal.vue";

export default GraphsMixin.extend({
    name       : "TimeLineGraph",
    components : { LinePart, GraphSubmenu, GraphHelp, GraphModal },
    mixins     : [ GraphsMixin ],
    computed   : {
        timeLocal(): string {
            return Duration.fromObject({ seconds: Math.round(this.aggregatedValue) }).toFormat("hh:mm:ss");
        },
        differenceLocal(): string {
            this.difference = this.difference * -1;
            return this.$formatNumber(Math.abs(this.difference));
        }
    },
});
