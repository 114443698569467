import AddGraphMenu from "@/components/add-graph/AddGraphMenu.vue";
import _Vue from "vue";

const AddGraphPlugin = {
    EventBus: new _Vue(),

    install(Vue: typeof _Vue): void {

        Vue.component("add-graph-menu", AddGraphMenu);

        Vue.prototype.$addGraphMenu = {
            show(position?: number): void { 
                AddGraphPlugin.EventBus.$emit("show", position);
            },
            hide(force = false): void {
                AddGraphPlugin.EventBus.$emit("hide", force);
            } 
        };
    },
};

export default AddGraphPlugin;
