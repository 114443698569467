import _Vue from "vue";

const FormatNumberPlugin = {
    install(Vue: typeof _Vue): void {
        Vue.prototype.$formatNumber = (number: number): string => {
            return Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(number);
        };
    },
};

export default FormatNumberPlugin;
