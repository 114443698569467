






























import Vue from "vue";
import AddGraphContainer from "@/components/add-graph/AddGraphContainer.vue";
import AddGraphPlugin from "@/plugins/show-add-graph-menu.ts";

export default Vue.extend({
    name       : "AddGraphMenu",
    components : {
        AddGraphContainer
    },
    data () {
        return {
            visible       : false,
            graphPosition : -1,
            search        : "",
            searchGraphs  : {},
            fullscreen    : false,
            graphs        : {
                messagesGraph: {
                    id          : 0,
                    name        : "Numbers Chart",
                    component   : "number-graph",
                    title       : "Messages",
                    dataPath    : "metrics/messages/sum",
                    dataLabel   : "Messages",
                    filters     : this.$store.state.graphs.filters,
                    standard    : true,
                    description : `The total amount of messages received and sent during the selected period.
                                  The percentage beneath the amount of messages shows the positive or negative
                                  change in percentage compared to the previous period of the same length.
                                  By switching to the line graph you can see exactly how many messages were sent
                                  and received on a specific weekday.`
                },
                receivedMessagesGraph: {
                    id          : 1,
                    name        : "Numbers Chart",
                    component   : "number-graph",
                    title       : "Messages Received",
                    dataPath    : "metrics/messages/sum?direction=IN",
                    dataLabel   : "Messages",
                    filters     : this.$store.state.graphs.filters,
                    standard    : true,
                    description : `The total amount of messages received during the selected period.
                                  The percentage beneath the amount of messages received shows the positive or negative
                                  change in percentage compared to the previous period of the same length.
                                  By switching to the line graph you can see exactly how many messages were received
                                  on a specific weekday.`
                },
                sendMessagesGraph: {
                    id          : 2,
                    name        : "Numbers Chart",
                    component   : "number-graph",
                    title       : "Messages Sent",
                    dataPath    : "metrics/messages/sum?direction=OUT",
                    dataLabel   : "Messages",
                    filters     : this.$store.state.graphs.filters,
                    standard    : true,
                    description : `The total amount of messages sent during the selected period.
                                  The percentage beneath the amount of messages sent shows the positive or negative
                                  change in percentage compared to the previous period of the same length.
                                  By switching to the line graph you can see exactly how many messages were sent
                                  on a specific weekday.`
                },
                messageDistributionGraph: {
                    id          : 3,
                    name        : "Bar Chart",
                    component   : "bar-graph",
                    title       : "Distribution of messages",
                    dataPath    : "metrics/messages/distribution",
                    dataLabel   : "Messages",
                    filters     : this.$store.state.graphs.filters,
                    standard    : true,
                    description : `The distribution of messages per day of the week.
                                  The percentage beneath the amount of  distributed messages shows the positive or negative
                                  change in percentage compared to the previous period of the same length.
                                  By hovering over the bars you can see the exact distributed of messages in a specific week.`
                },
                activeContactsGraph: {
                    id          : 4,
                    name        : "Line Chart",
                    component   : "line-graph",
                    title       : "Active Contacts",
                    dataPath    : "metrics/active-contacts/sum",
                    dataLabel   : "Active Contacts",
                    filters     : this.$store.state.graphs.filters,
                    standard    : true,
                    infoText    : "(only counted once per period)",
                    description : `The amount of active contacts. The contacts are only counted once per period.
                                  This means that only unique contacts count for this metric.
                                  By hovering over the line graph you can see exactly how many contacts were active on a specific day of the month.`
                },
                uniqueConversationsUserGraph: {
                    id          : 14,
                    name        : "Line Chart",
                    component   : "line-graph",
                    title       : "User initiated conversations",
                    dataPath    : "metrics/conversations/unique?initiatedBy=USER",
                    dataLabel   : "Unique Conversation",
                    filters     : this.$store.state.graphs.filters,
                    standard    : true,
                    description : `The total amount of user-initiated conversations in the selected period.
                                   This graph counts every conversation that is initiated by the response to a user message.
                                   Whenever your business replies to a user within the 24 hour customer care window, that message will be associated with a user-initiated conversation.
                                   The shown amount is indicative.`
                },
                uniqueConversationsBusinessGraph: {
                    id          : 15,
                    name        : "Line Chart",
                    component   : "line-graph",
                    title       : "Business initiated conversations",
                    dataPath    : "metrics/conversations/unique?initiatedBy=BUSINESS",
                    dataLabel   : "Unique Conversation",
                    filters     : this.$store.state.graphs.filters,
                    standard    : true,
                    description : `The total amount of business-initiated conversations in the selected period.
                                   This graph counts every conversation that was initiated by your business sending a user a message outside the 24 hour customer care window.
                                   The shown amount is indicative.`
                },
                distributedActiveContactsGraph: {
                    id          : 5,
                    name        : "Flag Chart",
                    component   : "flag-graph",
                    title       : "Messages per country",
                    dataPath    : "metrics/active-contacts/distribution",
                    dataLabel   : "Messages per country",
                    filters     : this.$store.state.graphs.filters,
                    standard    : true,
                    infoText    : "(only counted once per period)",
                    description : `The distribution of WhatsApp numbers per country. The countries are based on the country code of the phone numbers.
                                  By hovering over a bar from a country you can see how many messages originate from that country.
                                  This information can only be gathered when a WhatsApp channel is selected.`
                },
                sendPaidWATemplates: {
                    id          : 6,
                    name        : "Line Chart",
                    component   : "line-graph",
                    title       : "WhatsApp Templates Sent",
                    dataPath    : "metrics/messages/sent-paid-templates/sum?channel=WHATSAPP",
                    dataLabel   : "Sent Paid Templates",
                    filters     : this.$store.state.graphs.filters,
                    standard    : true,
                    description : `The amount of WhatsApp Templates that have been sent in the selected period.
                                  By hovering over the bars you can see the amount of distributed messages on a specific day of the month.`
                },
                resolvedConversationsGraph: {
                    id          : 7,
                    name        : "Line Chart",
                    component   : "line-graph",
                    title       : "Conversations Resolved",
                    dataLabel   : "Conversations Resolved",
                    dataPath    : "metrics/conversations/resolved",
                    filters     : this.$store.state.graphs.filters,
                    standard    : true,
                    description : `The amount of conversations that have been resolved in the selected period.
                                  By hovering over the bars you can see the amount of distributed messages on a specific day of the month.`
                },
                firstReplyTimeGraph: {
                    id             : 8,
                    name           : "Numbers Chart",
                    component      : "time-graph",
                    title          : "First Reply Time",
                    dataPath       : "metrics/conversations/first-reply-times",
                    aggregatorPath : "/average",
                    dataLabel      : "First Reply Time",
                    filters        : this.$store.state.graphs.filters,
                    standard       : true,
                    description    : `The fastest time a new conversation has been replied to in the selected period.
                                     The percentage beneath the reply time shows the positive or negative
                                     change in percentage compared to the previous period of the same length.
                                     By switching to graph mode you can see exactly what the fastest reply time is on a specific day of the month.`,
                },
                firstReplyTimeBusinessHoursGraph: {
                    id             : 14,
                    name           : "Numbers Chart",
                    component      : "time-graph",
                    title          : "First Reply Time During Business Hours",
                    dataPath       : "metrics/conversations/first-reply-times-business-hours",
                    aggregatorPath : "/average",
                    dataLabel      : "First Reply Time",
                    filters        : this.$store.state.graphs.filters,
                    standard       : true,
                    description    : `The fastest time a new conversation has been replied to in the selected period during business hours.
                                         The percentage beneath the reply time shows the positive or negative
                                         change in percentage compared to the previous period of the same length.
                                         By switching to graph mode you can see exactly what the fastest reply time is on a specific day of the month.`,
                },
                resolveTimeGraph: {
                    id             : 9,
                    name           : "Numbers Chart",
                    component      : "time-graph",
                    title          : "Resolve time",
                    dataPath       : "metrics/conversations/resolve-times",
                    aggregatorPath : "/average",
                    dataLabel      : "Resolve Time",
                    filters        : this.$store.state.graphs.filters,
                    standard       : true,
                    description    : `The fastest time a conversation has been resolved in the selected period.
                                     The percentage beneath the resolve time shows the positive or negative
                                     change in percentage compared to the previous period of the same length.
                                     By switching to graph mode you can see exactly what the fastest resolve time is on a specific day of the month.`,
                },
                tagsUsedGraph: {
                    id          : 10,
                    name        : "Bar Chart",
                    component   : "vertical-bar-graph",
                    title       : "Tags Used",
                    dataLabel   : "Tags Used",
                    dataPath    : "metrics/conversations/tags",
                    filters     : this.$store.state.graphs.filters,
                    standard    : true,
                    description : `The most used tags in the current selected period.
                                  The percentage next to the number of tags shows the positive or negative
                                  change in percentage compared to the previous period of the same length.
                                  By hovering over a tag you can see exactly how many times that tag has been used.`
                },
                averageConversationMessagesGraph: {
                    id          : 11,
                    name        : "Numbers Chart",
                    component   : "number-graph",
                    title       : "Average messages in conversation",
                    dataPath    : "metrics/conversations/messages/average",
                    dataLabel   : "Messages",
                    filters     : this.$store.state.graphs.filters,
                    standard    : true,
                    description : `The average amount of messages that a conversation has.
                                  The percentage beneath the number of average massages shows the positive or negative
                                  change in percentage compared to the previous period of the same length.
                                  By switching to graph mode you can see exactly what the average amount of messages is on a specific day of the month.`
                },
                averageWaitingOnContactGraph: {
                    id          : 12,
                    name        : "Time Line Chart",
                    component   : "time-line-graph",
                    title       : "Waiting time on customer reply",
                    dataPath    : "metrics/messages/response-times/average?direction=IN",
                    dataLabel   : "Average Waiting Time",
                    filters     : this.$store.state.graphs.filters,
                    standard    : true,
                    description : `The average time the agent waits for a customer to reply.
                                  The percentage next to the waiting time shows the positive or negative
                                  change in percentage compared to the previous period of the same length.
                                  By hovering over the line graph you can see exactly what the average waiting time is on a specific day of the month.`
                },
                averageWaitingOnAgentGraph: {
                    id          : 13,
                    name        : "Time Line Chart",
                    component   : "time-line-graph",
                    title       : "Waiting time on agent reply",
                    dataPath    : "metrics/messages/response-times/average?direction=OUT",
                    dataLabel   : "Average Waiting Time",
                    filters     : this.$store.state.graphs.filters,
                    standard    : true,
                    description : `The average time the customer waits for an agent to reply.
                                  The percentage next to the waiting time shows the positive or negative
                                  change in percentage compared to the previous period of the same length.
                                  By hovering over the line graph you can see exactly what the average waiting time is on a specific day of the month.`
                },
            }
        };
    },
    methods: {
        hide(): void {
            this.visible = false;
            this.fullscreen = false;

            this.graphPosition = -1;
        },
        show(position: number): void {
            if (position >= 0) {
                this.graphPosition = position;
            }
            this.visible = true;
        },
        filterGraphs(): void {
            const search = this.search;
            this.searchGraphs = this._.filter(this.graphs, function(graph) {
                return graph.name.indexOf(search) > -1
                    || graph.title.indexOf(search) > -1;
            });
        }
    },
    mounted() {
        Object.entries(this.graphs).forEach(entry => {
            const graphSettings: Graph = entry[1];

            if (graphSettings.standard) {
                this.$store.dispatch("graphs/addGraph", {
                    graph: graphSettings,
                });
            }
        });
    },
    beforeMount() {
        // here we need to listen for emitted events
        // we declared those events inside our plugin
        AddGraphPlugin.EventBus.$on("show", (position: number) => {
            this.show(position);
        });

        AddGraphPlugin.EventBus.$on("hide", (force = false) => {
            if (this.graphPosition >= 0 || force) {
                this.hide();
            }
        });
    }
});
