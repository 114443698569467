




























import Vue from "vue";
import TopBar from "@/views/TopBar.vue";
import GraphContainer from "@/views/GraphContainer.vue";
import GraphRow from "@/views/GraphRow.vue";
import NumberGraph from "@/components/graphs/NumberGraph.vue";
import BarGraph from "@/components/graphs/BarGraph.vue";
import FlagGraph from "@/components/graphs/FlagGraph.vue";
import VerticalBarGraph from "@/components/graphs/VerticalBarGraph.vue";
import LineGraph from "@/components/graphs/LineGraph.vue";
import CountryGraph from "@/components/graphs/CountryGraph.vue";
import AppsConversationGraph from "@/components/graphs/AppsConversationGraph.vue";
import AddGraphBox from "@/components/add-graph/AddGraphBox.vue";
import TimeGraph from "@/components/graphs/TimeGraph.vue";
import TimeLineGraph from "@/components/graphs/TimeLineGraph.vue";

export default Vue.extend({
    name       : "Home",
    components : {
        TopBar,
        GraphContainer,
        GraphRow,
        NumberGraph,
        VerticalBarGraph,
        BarGraph,
        FlagGraph,
        LineGraph,
        CountryGraph,
        AppsConversationGraph ,
        AddGraphBox,
        TimeGraph,
        TimeLineGraph
    },
    computed: {
        graphLayout(): Graph[] {

            return this.$store.state.graphs.layout;
        }
    },

});
