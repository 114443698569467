

















import Vue from "vue";
export default Vue.extend({
    name    : "GraphButton",
    methods : {
        switchMode(event: Event): void {
            event.preventDefault();
            this.$emit("modeSwitch", true);
        }
    }
});
